import { P } from "@angular/cdk/keycodes";
import { Component, OnInit } from "@angular/core";
import * as AWS from "aws-sdk";
import { environment } from "../../environments/environment";
import { TalentService } from "../talent.service";
@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.css"],
})
export class UserManagementComponent implements OnInit {
  is_admin: boolean = false;
  new_user_template = {
    id: "",
    user_name: "",
    first_name: "",
    last_name: "",
    email: "",
    is_admin: 0,
    phone_mobile: "",
    phone_work: "",
    active: 1,
    user_role: 1,
    edit_role_ownership_access: 0,
    role_email_notification: 1,
    cognito_status: {
      enabled: false,
      userStatus: "UNCONFIRMED",
    },
  };
  new_user: Object = Object.assign({}, this.new_user_template);
  all_users: Array<Object> = [];
  filter_input: string = "";
  filtered_users: Array<Object> = [];
  contact_number_in_one_page: number = 18;

  sortAsc: boolean = true;
  sortType: string = "id";

  show_detail: Array<boolean> = [];
  // Only one user allow to edit at a time
  edit_user: number = -1;
  user_types: Array<Object> = [];
  user_types_lookup: Object = {};
  // New button only display when no user is being edited
  show_create_new: boolean = false;

  isLoading: boolean = false;
  isDone: boolean = false;

  constructor(private talentService: TalentService) {}

  ngOnInit() {
    this.is_admin = this.talentService.is_admin;
    if (this.is_admin) {
      this.getAllUsers();
    }
  }

  getAllUsers() {
    this.talentService.getUserRoleTypes().subscribe((res) => {
      this.user_types = res;
      for (let type of this.user_types) {
        this.user_types_lookup[type["id"]] = type["title"];
      }

      this.talentService.getYodaweUsersFullData().subscribe((res) => {
        this.all_users = res;
        for (let user of this.all_users) {
          user["filter_input"] =
            user["first_name"] +
            user["last_name"] +
            user["user_name"] +
            user["email_address"] +
            user["phone_mobile"] +
            user["phone_work"] +
            this.user_types_lookup[user["user_role"]];
        }
        this.show_detail = new Array<boolean>(this.all_users.length + 1).fill(
          false
        );

        this.getUserInfoFromCognito();
      }, (err) => {console.error(err)});
    });
  }

  applyFilter() {
    if (this.filter_input == "") {
      this.filtered_users = this.all_users;
    } else {
      this.filtered_users = this.all_users.filter((user) => {
        return user["filter_input"]
          .toLowerCase()
          .includes(this.filter_input.toLowerCase());
      });
    }
  }

  clickEdit(user_id) {
    this.edit_user = user_id;
    let selected_user = this.all_users.find(
      (user) => String(user["id"]) == String(user_id)
    );
    selected_user["has_change_active"] = false;
  }

  getUserInfoFromCognito() {
    // AWS.config.update({
    //   region: "us-east-1",
    //   accessKeyId: environment.AWS_COGNITO_ACCESS_ID,
    //   secretAccessKey: environment.AWS_COGNITO_SECRET_ACCESS_KEY,
    // });
    // const identityProvide = new AWS.CognitoIdentityServiceProvider();
    // const UserPoolId = environment.AWS_COGNITO_USER_POOL_ID;
    // let params = {
    //   UserPoolId: UserPoolId,
    //   AttributesToGet: [],
    // };
    // identityProvide.listUsers(params, (err, data) => {
    //   if (err) {
    //     console.log(err);
    //   } else {
    //     let cognito_status = {};
    //     for (let cognito_user of data["Users"]) {
    //       cognito_status[String(cognito_user["Username"])] = {
    //         enabled: cognito_user["Enabled"],
    //         userStatus: cognito_user["UserStatus"],
    //       };
    //     }
    //     console.log(cognito_status);

    //     for (let user of this.all_users) {
    //       if (user["email_address"] in cognito_status) {
    //         user["cognito_status"] = cognito_status[user["email_address"]];
    //       } else {
    //         user["cognito_status"] = {
    //           enabled: false,
    //           userStatus: "UNCONFIRMED",
    //         };
    //       }
    //     }
    //   }
    //   this.applyFilter();
    // });

    this.talentService.listCognitoUsers().subscribe(data => {

      let cognito_status = {};
      for (let cognito_user of data["users"]) {
        cognito_status[String(cognito_user["username"])] = {
          enabled: cognito_user["enabled"],
          userStatus: cognito_user["status"],
        };
      }
      console.log(cognito_status);

      for (let user of this.all_users) {
        if (user["email_address"] in cognito_status) {
          user["cognito_status"] = cognito_status[user["email_address"]];
        } else {
          user["cognito_status"] = {
            enabled: false,
            userStatus: "UNCONFIRMED",
          };
        }
      }
      this.applyFilter();
    });
  }

  createNewUser(new_user) {
    if (
      !new_user["email_address"] ||
      !new_user["email_address"].includes("@")
    ) {
      alert("Please enter a valid email address");
      this.isLoading = false;
    } else if (new_user["first_name"] == "") {
      alert("Please enter a first name");
      this.isLoading = false;
    } else if (new_user["last_name"] == "") {
      alert("Please enter a last name");
      this.isLoading = false;
    } else if (new_user["user_name"] == "") {
      alert("Please enter a user name");
      this.isLoading = false;
    } else {
      this.talentService
        .hasDuplicatedUserEmail(new_user["email_address"])
        .subscribe((res) => {
          if (res) {
            alert("Email already exists in the system");
          } else {
            // AWS.config.update({
            //   region: "us-east-1",
            //   accessKeyId: environment.AWS_COGNITO_ACCESS_ID,
            //   secretAccessKey: environment.AWS_COGNITO_SECRET_ACCESS_KEY,
            // });
            // const identityProvide = new AWS.CognitoIdentityServiceProvider();
            // const UserPoolId = environment.AWS_COGNITO_USER_POOL_ID;
            // let params = {
            //   UserPoolId: UserPoolId,
            //   DesiredDeliveryMediums: ["EMAIL"],
            //   Username: new_user["email_address"],
            //   UserAttributes: [
            //     { Name: "email_verified", Value: "true" },
            //     { Name: "given_name", Value: this.new_user["first_name"] },
            //     { Name: "family_name", Value: this.new_user["last_name"] },
            //     { Name: "email", Value: this.new_user["email_address"] },
            //   ],
            // };

          //   identityProvide.adminCreateUser(params, (err, data) => {
          //     if (err) {
          //       console.log(err);
          //     } else {
          //       this.talentService
          //         .createNewYodaweUser(new_user)
          //         .subscribe((res) => {
          //           this.edit_user = -1;
          //           this.isLoading = false;
          //           this.isDone = true;
          //           setTimeout(() => {
          //             this.isDone = false;
          //           }, 2000);
          //         });
          //     }
          //   });
          //   this.getAllUsers();
          //   this.closeCreateNew();
          //   console.log("OK");
          // }
          this.talentService.createCognitoUser(this.new_user).subscribe((response) => {

              this.talentService
                .createNewYodaweUser(new_user)
                .subscribe((res) => {
                  this.edit_user = -1;
                  this.isLoading = false;
                  this.isDone = true;
                  setTimeout(() => {
                    this.isDone = false;
                  }, 2000);
                });
          });
          this.getAllUsers();
          this.closeCreateNew();
          console.log("OK");
        }
        }, (err) => console.error(err));
    }
  }

  saveEdit(user_id) {
    this.isLoading = true;
    let selected_user = this.all_users.find(
      (user) => String(user["id"]) == String(user_id)
    );
    if (!this.show_create_new) {
      if (selected_user["has_change_active"]) {
        this.updateActiveStatusOnCongnito(selected_user);
      }
      this.talentService.updateYodaweUser(selected_user).subscribe((res) => {
        this.edit_user = -1;
        this.isLoading = false;
        this.isDone = true;
        setTimeout(() => {
          this.isDone = false;
        }, 2000);
        this.getAllUsers();
      });
    } else {
      this.createNewUser(selected_user);
    }
  }

  updateActiveStatusOnCongnito(selected_user) {
    // AWS.config.update({
    //   region: "us-east-1",
    //   accessKeyId: environment.AWS_COGNITO_ACCESS_ID,
    //   secretAccessKey: environment.AWS_COGNITO_SECRET_ACCESS_KEY,
    // });
    // const identityProvide = new AWS.CognitoIdentityServiceProvider();
    // const UserPoolId = environment.AWS_COGNITO_USER_POOL_ID;
    // let params = {
    //   UserPoolId: UserPoolId,
    //   Username: selected_user["email_address"],
    // };



    // if (selected_user["active"]) {
    //   identityProvide.adminEnableUser(params, (err, data) => {
    //     if (err) {
    //       console.log(err);
    //     } else {
    //       console.log(data);
    //     }
    //   });
    // } else {
    //   identityProvide.adminDisableUser(params, (err, data) => {
    //     if (err) {
    //       console.log(err);
    //     } else {
    //       console.log(data);
    //     }
    //   });
    // }

    const params = {
      active: Boolean(selected_user['active']),
      email_address: selected_user["email_address"]
    }

    this.talentService.updateCognitoStatus(params).subscribe((res) => {
      console.log(res)
    },
    (err) => {
      console.error(err)
    }
  )
  }

  showCreateNew() {
    this.show_create_new = true;
    this.new_user = Object.assign({}, this.new_user_template);
    this.filtered_users.unshift(this.new_user);
  }

  changeSortType(sort_type) {
    if (sort_type == this.sortType) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortType = sort_type;
    }
  }

  closeCreateNew() {
    this.show_create_new = false;
    this.filtered_users.shift();
  }
}
